<template>
  <div class="app-container">
    <CrudTable entity="MenuCategory" :columns="columns" :default-filters="{ onlineYN: 1 }" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import { booleanColumnFilter, booleanColumnFormatter } from '@/utils/filters';
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        'name',
        {
          field: 'toGoYN',
          label: 'common.toGo',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this)
        },
        'position',
        {
          field: 'onlineYN',
          label: 'common.onlineYN',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this)
        }
      ]
    };
  }
};
</script>

<style scoped></style>
